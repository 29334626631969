/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const DownloadCard = ({
  children, version, compatible, date, license, releaseNotes,
}) => {
  const { t } = useTranslation()

  return (
      <div className="download-main-card">
          <div className="download-main-card-version"><p>{`VERSION ${version}`}</p></div>
          <div className="download-main-card-compatible"><p>{`for Arrowhead Framework ${compatible}`}</p></div>
          <div className="download-main-card-content">
              {children}
          </div>
          <div className="download-main-card-footer">
              <div><a href={license}>{t('read-license')}</a></div>
              <div><p>{date}</p></div>
              <div><a href={releaseNotes}>{t('release-notes')}</a></div>
          </div>
      </div>
  )
}

DownloadCard.propTypes = {
  children: PropTypes.node.isRequired,
  version: PropTypes.string.isRequired,
  compatible: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  license: PropTypes.string.isRequired,
  releaseNotes: PropTypes.string.isRequired,
}

export default DownloadCard

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "arrowhead", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
