import React from 'react'
import { I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import DownloadMain from '../../components/download_main'
import DownloadCard from '../../components/download_card'
import DownloadCardRow from '../../components/download_card_row'

const DownloadAhCertGen = () => {
  const { i18n } = React.useContext(I18nContext)

  return (
      <Layout>
          <SEO title="Arrowhead-Tools" language={i18n.language} />
          <DownloadMain
              productName="Arrowhead Certificate Generator"
              logoPic="ah-cert-gen-icon"
          >
              <DownloadCard
                  version="1.0.0"
                  compatible="4.5.0+"
                  date="03.11.2022"
                  license="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-certificate-generator/downloads/LICENSE.txt"
                  releaseNotes="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-certificate-generator/downloads/release-notes-v1.0.0.txt"
              >
                  <DownloadCardRow os="win" fileName="Arrowhead-Certificate-Generator-Setup-1.0.0.exe" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-certificate-generator/downloads/win/Arrowhead-Certificate-Generator-Setup-1.0.0.exe?raw=true" />
                  <DownloadCardRow os="lin" fileName="Arrowhead-Certificate-Generator-1.0.0.AppImage" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-certificate-generator/downloads/linux/Arrowhead-Certificate-Generator-1.0.0.AppImage?raw=true" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Certificate-Generator-1.0.0.dmg" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-certificate-generator/downloads/mac/Arrowhead-Certificate-Generator-1.0.0.dmg?raw=true" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Certificate-Generator-1.0.0-arm64.dmg" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-certificate-generator/downloads/mac/Arrowhead-Certificate-Generator-1.0.0-arm64.dmg?raw=true" />
              </DownloadCard>
          </DownloadMain>
      </Layout>
  )
}

export default DownloadAhCertGen

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "arrowhead", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
