import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const DownloadCardRow = ({
  os, fileName, path,
}) => {
  const { t } = useTranslation()

  return (
      <div className="download-main-card-content-row">
          <div className={`download-main-card-content-row-os download-main-card-content-row-os-${os}`} />
          <div className="download-main-card-content-row-name"><p>{fileName}</p></div>
          <div className="download-main-card-content-row-download"><a href={path}>{t('download').toUpperCase()}</a></div>
      </div>
  )
}

DownloadCardRow.propTypes = {
  os: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default DownloadCardRow

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "arrowhead", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
