import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const DownloadMain = ({ children, productName, logoPic }) => {
  const data = useStaticQuery(graphql`
    query DownloadMainImages {
        images: allFile(filter: { relativeDirectory: { eq: "downloads" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(logoPic))

  return (
      <div className="download-main">
          <div className="download-main-header">
              <div className="download-main-header-img-wrapper">
                  <Img fluid={image.childImageSharp.fluid} />
              </div>
              <div className="download-main-header-title"><p>{productName}</p></div>

          </div>
          { children }
      </div>
  )
}

DownloadMain.propTypes = {
  children: PropTypes.node.isRequired,
  productName: PropTypes.string.isRequired,
  logoPic: PropTypes.string.isRequired,
}

export default DownloadMain
